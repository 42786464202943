<template>
  <div>
    <b-card no-body>
      <!-- <div class="p-1">
        <b-row>
          <b-col
            v-if="$can('view', 'frontendmanagement')"
            class="col d-flex justify-content-end"
          >
            <b-button variant="primary">
              <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
            </b-button>
          </b-col>
        </b-row>
      </div> -->
      <b-table
        ref="refAgentGameCategoryTable"
        class="position-relative table-white-space mb-0"
        :items="fetchGameCategory"
        responsive
        :fields="tableColumns"
        primary-key="index"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(name_en)="data">
          {{ data.item.name_en }}
        </template>

        <template #cell(banner)="data">
          <div
            v-for="itemImg in data.item.banners"
            :key="itemImg.id"
           
          >
            <div  type="display: flex; margin:4px">
              <b-link
                :href="baseUrl + itemImg.src"
                target="_blank"
                class="cursor-pointer"
              >
                <b-img
                  :src="`${baseUrl + itemImg.src}`"
                  fluid
                  style="max-width: 200px"
                  class="mr-2 rounded"
                />
              </b-link>
            </div>
          </div>
        </template>

        <template #cell(created_at)="data">
          {{
            data.item.created_at
              ? formatDateTime(data.item.created_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(updated_at)="data">
          {{
            data.item.updated_at
              ? formatDateTime(data.item.updated_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            v-if="$can('update', 'gamecategory')"
            variant="primary"
            class="btn-icon btn-sm mr-50"
            @click="UpdateGameCategoryModal(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
<!--          <b-button-->
<!--            v-b-tooltip.hover.v-danger-->
<!--            :title="$t('Reject')"-->
<!--            v-if="$can('view', 'gamecategory')"-->
<!--            variant="danger"-->
<!--            class="btn-icon btn-sm"-->
<!--          >-->
<!--            <feather-icon icon="XIcon" />-->
<!--          </b-button>-->
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalGameCategory"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <update-game-category-modal
      :game-category-detail.sync="gameCategoryDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BAlert,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BImg,
  VBTooltip,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, onUnmounted, computed } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import agentStoreModule from "@/views/agent/agentStoreModule";
import baseUrl from "@/libs/base-url";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// import GameCategoryFilters from './GameCategoryFilters.vue'

export default {
  components: {
    // GameCategoryFilters,
    UpdateGameCategoryModal: () => import("./UpdateGameCategoryModal"),
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAlert,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      gameCategoryDetail: null,
    };
  },
  methods: {
    UpdateGameCategoryModal(datas) {
      this.gameCategoryDetail = datas;
      this.$bvModal.show("modal-update-game-category");
    },
  },
  setup() {
    const AGENT_MODULE_NAME = "agent";
    if (!store.hasModule(AGENT_MODULE_NAME))
      store.registerModule(AGENT_MODULE_NAME, agentStoreModule);
    onUnmounted(() => {
      if (store.hasModule(AGENT_MODULE_NAME))
        store.unregisterModule(AGENT_MODULE_NAME);
    });
    const gameCategory = ref();
    const fetchGameCategory = (ctx, callback) => {
      store
        .dispatch("agent/fetchGameCategory", {
          page: currentPage.value,
          perPage: perPage.value,
        })
        .then((response) => {
          if (response.data.code == "00") {
            callback(response.data.data);
            totalAgent.value = response.data.count;
            perPage.value =
              currentPage.value == 1 ? response.data.data.length : 25;
          }
        })
        .catch(() => {});
    };
    const perPage = ref(25);
    const totalGameCategory = ref(0);
    const currentPage = ref(1);
    const nameFilter = ref(null);
    const codeFilter = ref(null);
    const gameCategoryTypeFilter = ref(null);
    const gameCategoryTypeOptions = ref([]);
    const productFilter = ref(null);
    const productOptions = ref([]);
    const typeFilter = ref(null);
    const typeOptions = ref([]);
    const supportForFunFilter = ref(null);
    const supportForFunOptions = [
      { label: "Yes", value: 1 },
      { label: "No", value: 2 },
    ];
    const statusFilter = ref(null);
    const languageFilter = ref(null);
    const languageOptions = ref([]);
    const refAgentGameCategoryTable = ref(null);
    const tableColumns = [
      { key: "index", label: i18n.t("#") },
      { key: "name", label: i18n.t("Name") },
      { key: "name_en", label: i18n.t("English Name") },
      { key: "banner", label: i18n.t("Banner") },
      { key: "updated_at", label: i18n.t("Updated at") },
      { key: "updated_by", label: i18n.t("Updated by") },
      { key: "action", label: i18n.t("Actions") },
    ];

    const refetchData = () => {
      refAgentGameCategoryTable.value.refresh();
    };

    watch(
      [
        currentPage,
        nameFilter,
        codeFilter,
        gameCategoryTypeFilter,
        productFilter,
        typeFilter,
        supportForFunFilter,
        statusFilter,
        languageFilter,
      ],
      () => {
        refetchData();
      }
    );

    const dataMeta = computed(() => {
      const localItemsCount = refAgentGameCategoryTable.value
        ? refAgentGameCategoryTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalGameCategory.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "primary" };
      if (status == 2) return { label: "Inactive", variant: "danger" };
      return { label: "None", variant: "secondary" };
    };

    const resolveSupportForFun = (status) => {
      if (status == 1) return "Yes";
      if (status == 2) return "No";
      return "None";
    };

    return {
      tableColumns,
      perPage,
      currentPage,
      totalGameCategory,
      dataMeta,
      refAgentGameCategoryTable,
      refetchData,
      baseUrl,
      fetchGameCategory,

      nameFilter,
      codeFilter,
      gameCategoryTypeFilter,
      gameCategoryTypeOptions,
      productFilter,
      productOptions,
      typeFilter,
      typeOptions,
      supportForFunFilter,
      supportForFunOptions,
      statusFilter,
      statusOptions,
      languageFilter,
      languageOptions,
      formatDateTime,
      resolveStatus,
      resolveSupportForFun,
    };
  },
};
</script>